export const formatStaticPages = (data?: StaticPages[]) => {
  if (!data || data?.length === 0) {
    return []
  }

  const staticPages = data?.reduce((pages, currentItem) => {
    return [...pages, ...currentItem.static_pages]
  }, [] as StaticByTitle[])

  const sortedPages = staticPages.sort((a, b) => Number(a.id) - Number(b.id))

  const firstThreePages = sortedPages.slice(0, 3)

  return firstThreePages
}
