import React from 'react'

import { Link } from 'solito/link'

import { Stack } from '@mui/material'

import Text from 'app/components/base/Text'
import { useGetAllStaticPagesQuery } from 'app/services/Query/static-pages/static-pages.query'
import { STATIC_PAGE_VISIBILITY } from 'app/utils/constants/status.constants'
import { selectStaticPages } from 'app/utils/helpers/staticPage.helpers'

import { formatStaticPages } from '~/views/LandingView/helpers/landing.helpers'

const StaticPagesBlock = () => {
  const { data, isSuccess } = useGetAllStaticPagesQuery({
    options: {
      select: (data) =>
        selectStaticPages({ data, visibility: STATIC_PAGE_VISIBILITY.landing })
    }
  })

  const staticPages = formatStaticPages(data)
  const hasPages = !!staticPages?.length && isSuccess

  if (!hasPages) {
    return null
  }

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 3 }}
      sx={{
        justifyContent: 'center',
        alignItems: { xs: 'center', sm: 'initial' }
      }}
    >
      {staticPages?.map((item) => (
        <Link key={item.title} href={`/${item.slug}`} prefetch={false}>
          <Text
            variant="subtitle1"
            sx={{
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {item.title}
          </Text>
        </Link>
      ))}
    </Stack>
  )
}

export default StaticPagesBlock
