import dynamic from 'next/dynamic'
import React from 'react'

import { Link } from 'solito/link'

import { Grid } from '@mui/material'

import SocialLinksBlock from 'app/components/common/SocialLinksBlock'

import { LANDING_PATH } from '~/views/LandingView/helpers/landing.constants'

import StaticPagesBlock from './components/StaticPagesBlock'

const Logo = dynamic(() => import('app/assets/images/dark-logo.svg'))

const Footer = (): JSX.Element => {
  return (
    <Grid
      container
      alignItems="center"
      sx={{ px: { xs: 2, lg: 10, xxl: 25 }, mb: { xs: 8.75, md: 3.5 } }}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: { xs: 'flex', md: 'block' },
          justifyContent: { xs: 'center', md: 'left' }
        }}
      >
        <Link href={LANDING_PATH} prefetch={false}>
          <Logo />
        </Link>
      </Grid>

      <Grid item xs={12} md={4} sx={{ mt: { xs: 2, md: 0 } }}>
        <StaticPagesBlock />
      </Grid>

      <Grid item xs={12} md={4} sx={{ mt: { xs: 3, md: 0 } }}>
        <SocialLinksBlock
          spacing={2}
          sx={{ justifyContent: { xs: 'center', md: 'end' } }}
        />
      </Grid>
    </Grid>
  )
}

export default Footer
